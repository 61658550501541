import React from 'react';
import { faFacebook, faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment'
import './footer.style.css'
const Footer = () => {
    return (
        // <div class="footer mt-5">
        //     <div class="container">
        //         <div class="float-left">
        //         <span class=" text-muted">© {moment().format('YYYY')} Better Labour Inc. All Rights Reserved.
        //         <a class=" text-muted" href="https://betterlabour.com/privacy-policy/">| Privacy Policy</a>
        //             <a class=" text-muted"  href="https://betterlabour.com/recruiter-opportunities/">| Franchise Opportunities</a>
        //         </span>
        //         </div>
        //         <div class="float-right">
        //            <a className="text-muted p-2" href="https://www.facebook.com/BetterLabourInc/"><FontAwesomeIcon icon={faFacebook} /></a>
        //             <a className="text-muted p-2" href="https://twitter.com/LabourBetter"><FontAwesomeIcon icon={faTwitter} /></a>
        //             <a className="text-muted p-2" href="https://www.linkedin.com/company/better-labour-inc/"><FontAwesomeIcon icon={faLinkedin} /></a>
        //         </div>

        //     </div>
        // </div>
        <div id="footer">

<h5>I need all the details what to put here</h5>


            <div class="footer-top">
                <div class="container">
                    <div class="row">

                        <div class="col-lg-3 col-md-6 footer-contact">
                            <h3><span>Better Labour</span></h3>
                            <p>
                                2050 Steeles Ave. West, Unit 2 <br></br>
            Concord, ON<br></br>
            L4K 2V1 <br></br>
                                <strong>Phone:</strong> +1 416-535-5188<br></br>
                                <strong>Email:</strong>info@betterlabour.com<br></br>
                            </p>
                        </div>

                        <div class="col-lg-3 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">Home</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">About us</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">Services</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li>
                            </ul>
                        </div>

                        <div class="col-lg-3 col-md-6 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">Web Design</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">Web Development</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">Product Management</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">Marketing</a></li>
                                <li><i class="bx bx-chevron-right"></i> <a href="#">Graphic Design</a></li>
                            </ul>
                        </div>

                        <div class="col-lg-3 col-md-6 footer-links">
                            <h4>Our Social Networks</h4>
                            <p>Cras fermentum odio eu feugiat lide par naso tierra videa magna derita valies</p>
                            <div class="social-linksFt mt-3">
                                <a href="https://www.facebook.com/BetterLabourInc/"><i><FontAwesomeIcon icon={faFacebook} /></i></a>
                                <a href="https://twitter.com/LabourBetter"><i><FontAwesomeIcon icon={faTwitter} /></i></a>
                                <a href="https://www.linkedin.com/company/better-labour-inc/"><i><FontAwesomeIcon icon={faLinkedin} /></i></a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="container py-4">
                <div class="copyright">
                    &copy; {moment().format('YYYY')} Copyright <strong><span>Better Labour</span></strong>. All Rights Reserved </div>
            </div>
        </div>
    )
}

export default Footer