import React from 'react';
import './contact.style.css'
import { faMapMarked, faEnvelopeOpenText, faPhoneAlt, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContactHome = () => {
    return (
        <section id="contact" class="contact">
            <div class="container" data-aos="fade-up">

                <div class="section-title">
                    <h2>Contact</h2>
                    <h3><span>Better Labour Inc.</span></h3>
                    <p>CONCORD Location</p>
                </div>

                <div class="row" data-aos="fade-up" data-aos-delay="100">
                    <div class="col-lg-3 col-md-6">
                        <div class="info-box mb-4">
                            <i><FontAwesomeIcon icon={faMapMarked} size='1x' /></i>
                            <h3>Our Address</h3>
                            <p>2050 Steeles Ave. West, Unit 2
                            Concord, ON L4K 2V1</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="info-box  mb-4">
                            <i><FontAwesomeIcon icon={faEnvelopeOpenText} size='1x' /></i>
                            <h3>Email Us</h3>
                            <p>info@betterlabour.com</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="info-box  mb-4">
                            <i><FontAwesomeIcon icon={faPhoneAlt} size='1x' /></i>
                            <h3>Call Us</h3>
                            <p>+1 416-535-5188</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="info-box  mb-4">
                            <i><FontAwesomeIcon icon={faClock} size='1x' /></i>
                            <h3>Office Hours</h3>
                            <p>Mon - Fri 9:00 am - 5:00 pm</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
    )
}

export default ContactHome;