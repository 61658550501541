import React, {useState,useEffect} from 'react';
import Header from '../../components/_header/header.component'
import Footer from '../../components/_footer/footer.component'
import { useLocation } from "react-router-dom";

const ThankYou = () => {
    const [loading, setloading] = useState(true)
    const location = useLocation();
    const data = location.state

    useEffect(() => {
        if(data){
            setloading(false)
        }
    })
    
    if(loading){
        return(
            <div>Loading..</div>
        )
    }

    return(
        <>
            <Header />
            <div style={{paddingTop:'8rem', paddingBottom:'8rem'}} className="container justify-content-center">
                <div class="body">
                <h5>Thank you <span className="text-warning">{data.name}</span></h5>
                    <div className="text-muted"> We will send you confirmation email shortly!</div>
                </div>
            </div>
            <Footer />
            
        </>
    )
}

export default ThankYou;