import React, { Fragment, useEffect, useState } from "react";
import firebase from 'firebase/app';
import "firebase/storage";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import classnames from "classnames";
import './worker.style.css'
import {
    Row,
    Col,
    FormGroup,
    Input,
    Label,
} from "reactstrap";
import Header from '../../components/_header/header.component'
import Footer from '../../components/_footer/footer.component'
import IndustryCategoryService from '../../services/industry-category-service';
import { v4 as uuidv4 } from 'uuid';
import WorkerRegistrationService from '../../services/worker-registration-service';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
const alphaNumISHRegExp = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/
const Step1Schema = Yup.object().shape({
    name: Yup.string()
        .min(2, "* Name is required.")
        .required(),
    email: Yup.string()
        .email("* Must enter a valid email address")
        .required(),
    address: Yup.string()
        .required("* Street is required"),
    phoneNumber: Yup.string()
        .matches(phoneRegExp, "* Phone number is not valid")
        .required("* Phone number required"),
    gender: Yup.string()
        .required("* Gender is required"),
    dateOfBirth: Yup.string()
        .required("* Date Of Birth is required"),
    postalCode: Yup.string()
        .matches(alphaNumISHRegExp, "* Postal Code is not valid")
        .required("* Postal Code required"),
});
const Step2Schema = Yup.object().shape({
    jobType: Yup.string()
        .required("* Job Type is required"),
    // resume: Yup.string()
    //     .required("* Resume is required"),
    // industry: Yup.string()
    //     .required("* Industry is required")
});
const initialValues = {
    email: '',
    name: '',
    gender: '',
    dateOfBirth: '',
    address: '',
    postalCode: '',
    phoneNumber: '',
    jobType: '',
    extraComments: '',
};

const schemaArray = [Step1Schema, Step2Schema];

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const required = value => (value ? undefined : "Required");

class Wizard extends React.Component {
    static Page = ({ children, parentState }) => {
        return children(parentState);
    };

    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            values: props.initialValues
        };
    }

    next = values =>
        this.setState(state => ({
            page: Math.min(state.page + 1, this.props.children.length - 1),
            values
        }));

    previous = () =>
        this.setState(state => ({
            page: Math.max(state.page - 1, 0)
        }));

    validate = values => {
        const activePage = React.Children.toArray(this.props.children)[
            this.state.page
        ];
        return activePage.props.validate ? activePage.props.validate(values) : {};
    };

    handleSubmit = (values, bag) => {
        const { children, onSubmit } = this.props;
        const { page } = this.state;
        const isLastPage = page === React.Children.count(children) - 1;
        if (isLastPage) {
            return onSubmit(values, bag);
        } else {
            bag.setTouched({});
            bag.setSubmitting(false);
            this.next(values);
        }
    };

    arrayProgress = [
        {
            title: "Personal Details",
            description: "Your Personal Details....."
        },
        {
            title: "Professional Details",
            description: "Your Professional Details...."
        },
        {
            title: "Confirmation",
            description: "Process complete & next steps"
        }
    ];
    render() {
        const { children } = this.props;
        const { page, values } = this.state;
        const activePage = React.Children.toArray(children)[page];
        const isLastPage = page === React.Children.count(children) - 1;
        return (
            <Formik
                initialValues={values}
                enableReinitialize={false}
                // validate={this.validate}
                // validationSchema={this.schemaArray[page]}
                validationSchema={schemaArray[page]}
                onSubmit={this.handleSubmit}
            >
                {props => {
                    const { handleSubmit, isSubmitting, setFieldValue } = props;
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="progressbar-wrapper">
                                <ol className="progressbar">
                                    {this.arrayProgress.map((item, index) => {
                                        return (
                                            <li className={page >= index ? "active" : ""}>
                                                <h2>{item.title}</h2>
                                                <p className="text--size-small">{item.description}</p>
                                            </li>
                                        );
                                    })}
                                </ol>
                            </div>
                            {React.cloneElement(activePage, { parentState: { ...props } })}
                            <Row>
                                <Col xs={{ size: 6, offset: 3 }}>
                                    <div className="buttons">
                                        {page > 0 && (
                                            <button
                                                type="button"
                                                className="btn btn-warning"
                                                onClick={this.previous}
                                            >
                                                « Previous
                                            </button>
                                        )}

                                        {!isLastPage && <button className="btn btn-primary" type="submit">Next »</button>}
                                        {isLastPage && (
                                            <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                                                Submit
                                            </button>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    );
                }}
            </Formik>
        );
    }
}

export const WorkerRegistration = () => {
    const [loading, isLoading] = useState(true)
    const [url, seturl] = useState()
    const history = useHistory();
    const [industry, setindustry] = useState()
    const [industrySelected, setindustrySelected] = useState([])
    const animatedComponents = makeAnimated();
    console.log(industrySelected)
    useEffect(() => {
        IndustryCategoryService.getAll().get().then((querySnapshot) => {
            const IndustryOption = [];
            querySnapshot.forEach((doc) => {
                let id = doc.id;
                let data = doc.data();
                IndustryOption.push({
                    value: data.name,
                    label: data.name
                });
            });
            setindustry(IndustryOption)
            isLoading(false)
        });
    }, [])

    const firebaseUpload = (e) => {
        const file = e.target.files[0];
        const storageRef = firebase.storage().ref()
        const fileRef = storageRef.child(uuidv4())
        fileRef.put(file).then(async (snapshot) => {
            await snapshot.ref.getDownloadURL().then(url => {
                seturl(url)
            })
        })
    }
    if (loading) {
        return (
            <div>
                Loading..
            </div>
        )
    }
    return (
        <>
            <Header />
            <div className="App">
                <h1 style={{ color: '#f89e46' }} className="text-center pb-4" >User Registration</h1>
                <Wizard
                    initialValues={initialValues}
                    onSubmit={(values, actions) => {
                        const user = { ...values, resume: url, createAt: moment().format('DD-MM-YYYY hh:mm'), industry:industrySelected }
                        WorkerRegistrationService.create(user)
                            .then(() => {
                                console.log("Created new item successfully!");
                                history.push({
                                    pathname: '/ThankYou',
                                    state: user
                                });
                                // actions.setSubmitting(false);
                            })
                            .catch((e) => {
                                console.log(e);
                            });
                        // sleep(300).then(() => {
                        //     const file = values.resume.files;
                        //     const storageRef = firebase.storage().ref()
                        //     const fileRef = storageRef.child(uuidv4())
                        //     fileRef.put(file).then((snapshot) => {
                        //         snapshot.ref.getDownloadURL().then(url => {
                        //             console.log(' * new url', url)
                        //         })
                        //     })
                        //     window.alert(JSON.stringify(values, null, 2));
                        //     actions.setSubmitting(false);
                        // });
                    }}
                >
                    <Wizard.Page>
                        {props => {
                            return (
                                <Fragment>
                                    <div>
                                        <Row>
                                            <Col xs={{ size: 6, offset: 3 }}>
                                                <FormGroup>
                                                    <TextInput
                                                        id="name"
                                                        type="text"
                                                        label="name"
                                                        placeholder="John Doe"
                                                        error={props.touched.name && props.errors.name}
                                                        value={props.values.name}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={{ size: 6, offset: 3 }}>
                                                <FormGroup>
                                                    <TextInput
                                                        id="email"
                                                        type="text"
                                                        label="Email Address"
                                                        placeholder="johndoe@example.com"
                                                        error={props.touched.email && props.errors.email}
                                                        value={props.values.email}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={{ size: 6, offset: 3 }}>
                                                <FormGroup>
                                                    <div className="dropdown-wrapper">
                                                        <Label>Gender</Label>
                                                        <Input
                                                            tag={Field}
                                                            name="gender"
                                                            component="select"
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                            <option value="Other">Other's</option>
                                                        </Input>
                                                    </div>
                                                    <ErrorMessage
                                                        name="gender"
                                                        component="div"
                                                        className="input-feedback"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={{ size: 6, offset: 3 }}>
                                                <FormGroup>
                                                    <TextInput
                                                        id="dateOfBirth"
                                                        type="date"
                                                        label="Date Of Birth"
                                                        error={props.touched.dateOfBirth && props.errors.dateOfBirth}
                                                        value={props.values.dateOfBirth}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={{ size: 6, offset: 3 }}>
                                                <FormGroup>
                                                    <TextInput
                                                        id="address"
                                                        type="text"
                                                        label="Address"
                                                        error={props.touched.address && props.errors.address}
                                                        value={props.values.address}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={{ size: 6, offset: 3 }}>
                                                <FormGroup>
                                                    <TextInput
                                                        id="postalCode"
                                                        type="text"
                                                        label="Postal Code"
                                                        error={props.touched.postalCode && props.errors.postalCode}
                                                        value={props.values.postalCode}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={{ size: 6, offset: 3 }}>
                                                <FormGroup>
                                                    <TextInput
                                                        id="phoneNumber"
                                                        type="number"
                                                        label="Phone Number"
                                                        error={props.touched.phoneNumber && props.errors.phoneNumber}
                                                        value={props.values.phoneNumber}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                    </div>
                                </Fragment>
                            );
                        }}
                    </Wizard.Page>
                    <Wizard.Page
                        validate={values => {
                            const errors = {};
                            if (!values.email) {
                                errors.email = "Required";
                            }
                            return errors;
                        }}
                    >
                        {props => {
                            return (
                                <Fragment>
                                    <div>
                                        <Row>
                                            <Col xs={{ size: 6, offset: 3 }}>
                                                <FormGroup>
                                                    <div className="dropdown-wrapper">
                                                        <Label>Job Type</Label>
                                                        <Input
                                                            tag={Field}
                                                            name="jobType"
                                                            component="select"
                                                        >
                                                            <option value="">Select</option>
                                                            <option value="FullTime">Full-Time</option>
                                                            <option value="PartTime">Part-Time</option>
                                                            {/* <option value="Contract">Contract</option>
                                                        <option value="OnCall">On-Call</option>
                                                        <option value="Contract">Work From Home</option> */}
                                                        </Input>
                                                    </div>
                                                    <ErrorMessage
                                                        name="jobType"
                                                        component="div"
                                                        className="input-feedback"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={{ size: 6, offset: 3 }}>
                                                <label htmlFor="resume">Upload Resume/CV File</label><br></br>
                                                <input id="resume" name="resume" type="file" onChange={firebaseUpload} className="pb-4" required />
                                            </Col>
                                            <Col xs={{ size: 6, offset: 3 }}>
                                                <FormGroup>
                                                    <div className="dropdown-wrapper">
                                                        <Label>Industry</Label>
                                                        <Select
                                                            components={animatedComponents}
                                                            isMulti
                                                            options={industry}
                                                            onChange={setindustrySelected}
                                                            placeholder="Select Industry"
                                                            noOptionsMessage={()=>"Industry not found"}
                                                            autoFocus
                                                            isSearchable
                                                        />
                                                        {/* <Input
                                                            tag={Field}
                                                            name="industry"
                                                            component="select"
                                                        >
                                                            <option value="" >Select</option>
                                                            {IndustryOption.map(({ id, name }, index) => <option value={name} >{name}</option>)}
                                                        </Input> */}
                                                    </div>
                                                    <ErrorMessage
                                                        name="industry"
                                                        component="div"
                                                        className="input-feedback"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs={{ size: 6, offset: 3 }}>
                                                <FormGroup>
                                                    <TextInput
                                                        id="extraComments"
                                                        type="textarea"
                                                        label="Comments"
                                                        error={props.touched.extraComments && props.errors.extraComments}
                                                        value={props.values.extraComments}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                </Fragment>
                            );
                        }}
                    </Wizard.Page>
                </Wizard>
            </div>
            <Footer />
        </>
    );
};

export default WorkerRegistration;

/**
 *
 * Custom Inputs for sirName only
 */
const InputFeedback = ({ error }) =>
    error ? <div className="input-feedback">{error}</div> : null;

const Labely = ({ error, className, children, ...props }) => {
    return (
        <label className="label" {...props}>
            {children}
        </label>
    );
};

const TextInput = ({
    type,
    id,
    label,
    error,
    value,
    onChange,
    className,
    ...props
}) => {
    const classes = classnames(
        "input-group",
        {
            "animated shake error": !!error
        },
        className
    );
    return (
        <div className={classes}>
            <Labely htmlFor={id} error={error}>
                {label}
            </Labely>
            <input
                id={id}
                className="text-input"
                type={type}
                value={value}
                onChange={onChange}
                {...props}
            />
            <InputFeedback error={error} />
        </div>
    );
};