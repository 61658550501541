import React, { useState, useEffect } from 'react';
import ResidentService from '../../../services/resident-service';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {getGeoCode} from '../../../redux/Actions/Address'
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

const ResidentAdmin = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [company, setCompany] = useState()
    const [loading, isLoading] = useState(true)
    const [deleteItemState, SetDeleteItemState] = useState(false)

    const deleteItem = (id) => {
        ResidentService.delete(id)
        .then(() => {
            SetDeleteItemState(true)
        })
        .catch((e) => {
          console.log(e);
        });
    }

    const sendWorker = (data) => {
        dispatch(getGeoCode(data.address))
        history.push({
            pathname: '/react-admin/sendWorker',
            state: data
        });
    }

    useEffect(() => {
        ResidentService.getAll().get().then((querySnapshot) => {
            const companyData = []
            querySnapshot.forEach((doc) => {
                let id = doc.id;
                let data = doc.data();
                companyData.push({
                    id: id,
                    address: data.address,
                    companies: data.companies,
                    createAt: data.createAt,
                    email: data.email,
                    name: data.name,
                    phonenumber: data.phonenumber,
                });
            });
            setCompany(companyData)
            isLoading(false)
            console.log(loading)
        });
    }, [deleteItemState])

    

    if (loading) {
        return (
            <div>
                Loading
            </div>
        )
    }
    return (
        <div className="container">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Address</th>
                        <th scope="col">Resident</th>
                        <th scope="col">Phone Number</th>
                    </tr>
                </thead>
                {
                    company.map(obj => {
                        return (
                            <tbody key={obj.id}>
                                <tr>
                                    <td>{obj.name}</td>
                                    <td>{obj.email}</td>
                                    <td>{obj.address}</td>
                                    <td>{obj.companies}</td>
                                    <td>{obj.phonenumber}</td>
                                    <td>
                                        <button className="btn btn-primary" onClick={() => sendWorker(obj)}>
                                            Send Workers
                                        </button>
                                    </td>
                                    <td><button onClick={() => deleteItem(obj.id)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                    </button></td>
                                </tr>
                            </tbody>
                        )
                    })
                }
            </table>
        </div>
    );
}

export default ResidentAdmin