import React from 'react'
import DeliveryForm from '../../components/_form/delivery.form'
import Header from '../../components/_header/header.component'
import Footer from '../../components/_footer/footer.component'
const DeliveryRegister = () => {

    return (
        <>
            <Header />
            <div className="container pt-4 justify-content-center">
                <h5>Delivery Plus</h5>
                <div class="body">
                    <DeliveryForm />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default DeliveryRegister