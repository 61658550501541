import React from 'react';
import { faIndustry, faAddressBook, faUser} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './featured.style.css'

const FeaturedServices = () => {
    return (
        <section id="featured-services" class="featured-services mt-5">
            <div class="container" data-aos="fade-up">

                <div class="row">
                    <div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                        <div class="icon-box">
                            <div class="icon"><FontAwesomeIcon icon={faAddressBook} size = '5x'/></div>
                            <h4 class="title"><a href="https://betterlabour.com/attract-more-attention-sales/">How To Attract More Attention To Your RESUME</a></h4>
                            <p class="description">When you apply for a job online application process, it's very likely that your resume will..</p>
                            <hr></hr>
                            <a rel="noreferrer" target="_blank" href="https://betterlabour.com/attract-more-attention-sales/" class="btn-get-started scrollto">Read More</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                        <div class="icon-box">
                            <div class="icon"><FontAwesomeIcon icon={faIndustry} size = '5x'/></div>
                            <h4 class="title"><a href="https://betterlabour.com/dummy-post/">How To Ace An Interview</a></h4>
                            <p class="description">Think about this -There are no dress rehearsals for first impressions!!! We are judged at...</p>
                            <hr></hr>
                            <a rel="noreferrer" target="_blank" href="https://betterlabour.com/dummy-post/" class="btn-get-started scrollto">Read More</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 d-flex align-items-stretch mb-5 mb-lg-0">
                        <div class="icon-box">
                            <div class="icon"><FontAwesomeIcon icon={faUser} size = '5x'/></div>
                            <h4 class="title"><a href="https://betterlabour.com/hello-world/">CAREER ADVICE</a></h4>
                            <p class="description">Stop looking for that special career/job. Just find a job. There's a lots of work out ther...</p>
                            <hr></hr>
                            <a rel="noreferrer" target="_blank" href="https://betterlabour.com/hello-world/" class="btn-get-started scrollto">Read More</a>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default FeaturedServices;