import { ADDRESS , ERROR } from '../Types';
import Geocode from "react-geocode";

export const getGeoCode = payload => {
    return (dispatch) => {
        Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY)
        Geocode.setLanguage("en")
        Geocode.setRegion("ca");
        Geocode.setLocationType("ROOFTOP");
        Geocode.enableDebug();
        Geocode.fromAddress(payload)
        .then(response => {
            const addressData = {
                lat:'',
                lng:''
            }
            addressData.lat = response.results[0].geometry.location.lat;
            addressData.lng = response.results[0].geometry.location.lng;
            dispatch({
                type: ADDRESS,
                payload: addressData
            })
        }).catch(error => {
            dispatch({
                type: ERROR,
                payload: error
            })
            console.log(`Error: ${error}`)
        })
    }
}
