import { ERROR, ADDRESS } from '../Types';

const intialState = {
    address: {},
    isEmpty: true
}

const reducer = (state = intialState, action) => {
    switch (action.type) {
        case ADDRESS: {
            return {
                ...state,
                address: action.payload,
                isEmpty: false
            }
        }
        case ERROR: {
            return {
                ...state,
                address: {},
                isEmpty: true
            }
        }
        default:
            return state

    }
}
export default reducer;