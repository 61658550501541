import React from 'react';
import './hero.style.css'
const HeroHome = () => {
  return (
    <div>
      <section id="hero" style={{ backgroundImage: "url(" + "images/hero-bg.jpg" + ")", }} class="d-flex align-items-center">
        <div class="container" data-aos="zoom-out" data-aos-delay="100">
          <h1>Welcome to <span>Better Labour</span>
          </h1>
          <h2>Better Workers - Better Service - Better Value</h2>
          <div class="d-flex">
            <a rel="noreferrer" target="_blank" href="https://betterlabour.com/register/" class="btn-get-started scrollto">Get Started</a>
            {/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" class="venobox btn-watch-video" data-vbtype="video" data-autoplay="true"> Watch Video <i class="icofont-play-alt-2"></i></a> */}
          </div>
        </div>
      </section>
    </div>
  )
}

export default HeroHome;