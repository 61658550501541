import React, { useState, createRef, useEffect } from "react";
import { Formik } from "formik";
import { Button, Col, Form, Row } from "react-bootstrap";
import FormTextField from "./form.text.field";
import FormSelectField from "./form.select.field";
import validation from "./validation";
import moment from 'moment';
import ResidentService from '../../services/resident-service';
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import ResidentCategoryService from '../../services/resident-category-service';


const TEST_SITE_KEY = "6LfSUFsaAAAAAIKmCoymWJExN4Xvqykde6heiemv";
const ResidentOption = [];

const ResidentForm = () => {  
    const history = useHistory();
    const _reCaptchaRef = createRef();
    const [loading, isLoading] = useState(true)

    useEffect(() => {
        ResidentCategoryService.getAll().get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                let id = doc.id;
                let data = doc.data();
                ResidentOption.push({
                    id: id,
                    name: data.name
                });
            });
            isLoading(false)
        });
    })

    const handleSubmit = values => {
        _reCaptchaRef.current.execute()
        const userRegister = { ...values, createAt: moment().format('DD-MM-YYYY hh:mm') }
        ResidentService.create(userRegister)
            .then(() => {
                console.log("Created new item successfully!");
                history.push({
                    pathname: '/ThankYou',
                    state: values
                });
            })
            .catch((e) => {
                console.log(e);
            });
    };

    if (loading) {
        return (
            <div>
                Loading..
            </div>
        )
    }
    return (
        <Row>
            <Col>
                <Formik
                    validationSchema={validation}
                    onSubmit={handleSubmit}
                    initialValues={{
                        name: "",
                        address: "",
                        phonenumber: "",
                        email: "",
                        companies:"",
                        recaptcha: ""
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors,
                        isValid,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Col>
                                <Form.Row>
                                    <FormTextField
                                        as={Col}
                                        controlId="validationFormik01"
                                        label="Name"
                                        type="text"
                                        name="name"
                                    />
                                </Form.Row>
                            </Col>
                            <Col>
                                <Form.Row>
                                    <FormTextField
                                        as={Col}
                                        controlId="validationFormik03"
                                        label="Address"
                                        type="text"
                                        name="address"
                                    />
                                </Form.Row>
                            </Col>
                            <Col>
                                <Form.Row>
                                    <FormTextField
                                        as={Col}
                                        controlId="validationFormik08"
                                        label="Phone Number"
                                        type="text"
                                        name="phonenumber"
                                    />
                                </Form.Row>
                            </Col>
                            <Col>
                                <Form.Row>
                                    <FormTextField
                                        as={Col}
                                        controlId="validationFormik04"
                                        label="Email Address"
                                        type="text"
                                        name="email"
                                    />
                                </Form.Row>
                            </Col>
                            <Col>
                                <Form.Row>
                                    <FormSelectField
                                        as={Col}
                                        controlId="validationFormik06"
                                        label="Resident"
                                        type="text"
                                        name="companies"
                                    >
                                        <option></option>
                                        {ResidentOption.map(({ id, name }, index) => <option value={name} >{name}</option>)}
                                    </FormSelectField>
                                </Form.Row>
                            </Col>
                            <ReCAPTCHA
                                ref={_reCaptchaRef}
                                sitekey={TEST_SITE_KEY}
                                onChange={(value) => setFieldValue("recaptcha", value)}
                                size="invisible"
                                name="recaptcha"
                            />
                            <Col>
                                <Button
                                    disabled={!isValid || isSubmitting}
                                    variant="success"
                                    as="input"
                                    size="lg"
                                    type="submit"
                                    value="Submit"
                                />
                            </Col>
                        </Form>
                    )}
                </Formik>
            </Col>
        </Row>
    );
}

export default ResidentForm;
