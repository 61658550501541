import React, { useState, useEffect } from 'react';
import WorkerRegistrationService from '../../../services/worker-registration-service';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory} from "react-router-dom";
import Select from 'react-select';
import firebase from 'firebase/app';
import "firebase/storage";

const WorkerAdmin = () => {
    const history = useHistory();
    const [user, setUser] = useState()
    const [loading, isLoading] = useState(true)
    const [deleteItemState, SetDeleteItemState] = useState(false)
    
    const deleteItem = async (data) => {
        const httpsReference = firebase.storage().refFromURL(data.resume)
        await httpsReference.delete()
            .then(() => {
            }).catch((e) => {
                console.log(e);
            });
        await WorkerRegistrationService.delete(data.id)
            .then(() => {
                SetDeleteItemState(true)
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const userDetails = (data) => {
        history.push({
            pathname: '/react-admin/user-details',
            state: data
        });
    }

    useEffect(() => {
        WorkerRegistrationService.getAll().get().then((querySnapshot) => {
            const userData = []
            querySnapshot.forEach((doc) => {
                let id = doc.id;
                let data = doc.data();
                userData.push({
                    id: id,
                    address: data.address,
                    createAt: data.createAt,
                    email: data.email,
                    name: data.name,
                    phoneNumber: data.phoneNumber,
                    dateOfBirth: data.dateOfBirth,
                    extraComments: data.extraComments,
                    gender: data.gender,
                    industry: data.industry,
                    jobType: data.jobType,
                    postalCode: data.postalCode,
                    resume: data.resume
                });
            });
            setUser(userData)
            isLoading(false)
            console.log(loading)
        });
    }, [deleteItemState])

    

    if (loading) {
        return (
            <div>
                Loading
            </div>
        )
    }
    return (
        <div className="container">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        {/* <th scope="col">Industry</th> */}
                        <th scope="col">Job Type</th>
                        <th scope="col">Resume</th>
                        <th scope="col">Full Details</th>
                    </tr>
                </thead>
                {
                    user.map(obj => {
                        return (
                            <tbody key={obj.id}>
                                <tr>
                                    <td>{obj.name}</td>
                                    <td>{obj.email}</td>
                                    {/* <td>
                                        <Select
                                            isMulti
                                            value={obj.industry}
                                        />
                                    </td> */}
                                    <td>{obj.jobType}</td>
                                    <td>
                                    <a className="btn btn-primary" href={`${encodeURI(obj.resume)}`} target="_blank">View</a>
                                    </td>
                                    <td>
                                        <button className="btn btn-primary" onClick={() => userDetails(obj)}>
                                            Details
                                        </button>
                                    </td>
                                    <td><button onClick={() => deleteItem(obj)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                    </button></td>
                                </tr>
                            </tbody>
                        )
                    })
                }
            </table>
        </div>
    );
}

export default WorkerAdmin;