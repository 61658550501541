import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './react-admin.style.css'

const AdminNavbar = React.lazy(() => import('../../components/_adminNavbar/adminnav.component'));
const SideBar = React.lazy(() => import('../../components/_sidebar/sidebar.component'));
const CompanyAdmin = React.lazy(() => import('./_company.admin/company-admin'));
const AdminHome = React.lazy(() => import('./_home.admin/home-admin'));
const DeliveryAdmin = React.lazy(() => import('./_delivery.admin/delivery-admin'));
const ResidentAdmin = React.lazy(() => import('./_resident.admin/resident-admin'));
const SentWorker = React.lazy(() => import('./_sentWorker.admin/sent-worker-admin'));
const IndustryHome = React.lazy(() => import('./_industry.admin/industry.home'));
const WorkerAdmin = React.lazy(() => import('./_workers.admin/worker.admin'));
const WorkerDetail = React.lazy(() => import('./_workers.admin/worker.detail'));

export const Dashboard = () => {

    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                <div className="row">
                    <div className="col-md-3 sidePanelBackground">
                        <Route path="/react-admin">
                            <SideBar />
                        </Route>
                    </div>
                    <div className="col-md-9" style={{ padding: "0" }}>
                        <Route path="/react-admin">
                            <AdminNavbar />
                        </Route>

                        <Switch>
                            <Route path="/react-admin" exact component={AdminHome} />
                            <Route path="/react-admin/worker-admin" exact component={WorkerAdmin} />
                            <Route path="/react-admin/company-admin" exact component={CompanyAdmin} />
                            <Route path="/react-admin/delivery-admin" exact component={DeliveryAdmin} />
                            <Route path="/react-admin/resident-admin" exact component={ResidentAdmin} />
                            <Route path="/react-admin/sendWorker" exact component={SentWorker} />
                            <Route path="/react-admin/industry" exact component={IndustryHome} />
                            <Route path="/react-admin/user-details" exact component={WorkerDetail} />
                        </Switch>
                    </div>
                </div>
            </Suspense>
        </Router>
    )
}

export default Dashboard;