import firebase from 'firebase/app'
import * as firestore from 'firebase/firestore';

let config = {
    apiKey: "AIzaSyAiQ3X_yPy00dGnHr7rfsKgvYZ2T-657wI",
    authDomain: "betterlabour.firebaseapp.com",
    databaseURL: "https://betterlabour-default-rtdb.firebaseio.com",
    projectId: "betterlabour",
    storageBucket: "betterlabour.appspot.com",
    messagingSenderId: "243397237103",
    appId: "1:243397237103:web:aa2fd2df7b0cda63fa709a",
    measurementId: "G-LB1FKMTQLR"
};

firebase.initializeApp(config);

export default firebase.firestore();