import React, {useEffect, useState} from 'react';
import { useLocation, useHistory } from "react-router-dom";
import WorkerRegistrationService from '../../../services/worker-registration-service';
import IndustryCategoryService from '../../../services/industry-category-service'
import Select from 'react-select';
import firebase from 'firebase/app';
import "firebase/storage";

const WorkerDetail = () =>{
    const history = useHistory();
    const [loading, setloading] = useState(true)
    const location = useLocation();
    const data = location.state
    const [industry, setindustry] = useState()
    const [industrySelected, setindustrySelected] = useState(data.industry)
    useEffect(() => {
        if(data){IndustryCategoryService.getAll().get().then((querySnapshot) => {
            const IndustryOption = [];
            querySnapshot.forEach((doc) => {
                let id = doc.id;
                let data = doc.data();
                IndustryOption.push({
                    value: data.name,
                    label: data.name
                });
            });
            setindustry(IndustryOption)
            setloading(false)
        });
        }
    })

    const deleteItem = async (data) => {
        const httpsReference = firebase.storage().refFromURL(data.resume)
        await httpsReference.delete()
            .then(() => {
            }).catch((e) => {
                console.log(e);
            });
        await WorkerRegistrationService.delete(data.id)
            .then(() => {
                history.push({
                    pathname: '/react-admin/worker-admin',
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const updateItem = (data) => {
        console.log(industrySelected)
        WorkerRegistrationService.update(data.id, {
            industry: industrySelected
        }).then(()=>{
            history.push({
                pathname: '/react-admin/worker-admin',
            });
        }) .catch((e) => {
            console.log(e);
        });
    }

    if(loading){
        return(
            <div>Loading..</div>
        )
    }


    return(
        <div className="container">
            <h1>Profile Details of <span style={{color:'#f89e46'}}>{data.name}</span> </h1>
            <div class="pt-4 form-group row">
                <label className="col-md-3">Name</label>
                <input className="col-md-4" placeholder={data.name} readonly disabled/>
            </div>
            <div class="form-group row">
                <label className="col-md-3">Address</label>
                <input className="col-md-4" placeholder={data.address} readonly disabled/>
            </div>
            <div class="form-group row">
                <label className="col-md-3">Postal Code</label>
                <input className="col-md-4" placeholder={data.postalCode} readonly disabled/>
            </div>
            <div class="form-group row">
                <label className="col-md-3">Email</label>
                <input className="col-md-4" placeholder={data.email} readonly disabled/>
            </div>
            <div class="form-group row">
                <label className="col-md-3">Phone Number</label>
                <input className="col-md-4" placeholder={data.phoneNumber} readonly disabled/>
            </div>
            <div class="form-group row">
                <label className="col-md-3">Date of Birth</label>
                <input className="col-md-4" placeholder={data.dateOfBirth} readonly disabled/>
            </div>
            <div class="form-group row">
                <label className="col-md-3">Gender</label>
                <input className="col-md-4" placeholder={data.gender} readonly disabled/>
            </div>
            <div class="form-group row">
                <label className="col-md-3">Industry</label>
                <Select
                    isMulti
                    defaultValue={industrySelected}
                    options={industry}
                    onChange={setindustrySelected}
                    autoFocus
                    isSearchable
                    noOptionsMessage={()=>"Industry not found"}
                />
            </div>
            <div class="form-group row">
                <label className="col-md-3">Job type</label>
                <input className="col-md-4" placeholder={data.jobType} readonly disabled/>
            </div>
            <div class="form-group row">
                <label className="col-md-3">Extra Comments</label>
                <input className="col-md-4" placeholder={data.extraComments} readonly disabled/>
            </div>
            <div class="form-group row">
                <label className="col-md-3">Registered On</label>
                <input className="col-md-4" placeholder={data.createAt} readonly disabled/>
            </div>
            <div class="form-group row">
                <a className="m-2 col-md-2 btn btn-primary" href={`${encodeURI(data.resume)}`} target="_blank">View Resume</a>
                <button className="m-2 col-md-2 btn btn-danger" onClick={() => deleteItem(data)}>
                    Delete
                </button>
                <button className="m-2 col-md-2 btn btn-warning" onClick={() => updateItem(data)}>
                    Update
                </button>
            </div>
        </div>
    )
}

export default WorkerDetail