import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import PrivateRoute from "./PrivateRoute"
import { AuthProvider } from "./contexts/AuthContext"

import Home from './pages/_home/home.page'
import CompanyRegister from './pages/_company/company-registration'
import ResidentRegister from './pages/_resident/resident-registration'
import DeliveryRegister from './pages/_delivery/delivery-registration'
import WorkerRegistration from './pages/_workerRegistration/worker.registration.page'
import ThankYou from './pages/_thankyou/thankyou.page'
import Login from './pages/_login/login.page'
import Dashboard from './pages/_admin/react-admin'
import CompanyAdmin from './pages/_admin/_company.admin/company-admin'
import DeliveryAdmin from './pages/_admin/_delivery.admin/delivery-admin'
import ResidentAdmin from './pages/_admin/_resident.admin/resident-admin'
import WorkerAdmin from './pages/_admin/_workers.admin/worker.admin'
import SentWorker from './pages/_admin/_sentWorker.admin/sent-worker-admin'
import WorkerDetail from './pages/_admin/_workers.admin/worker.detail'
function App() {
  return (
    <Router>
      <AuthProvider>
        <Route path="/" exact component={Home} />
        <Route path="/Login" exact component={Login} />
        <Route path="/CompanyRegister" exact component={CompanyRegister} />
        <Route path="/ResidentRegister" exact component={ResidentRegister} />
        <Route path="/DeliveryPlus" exact component={DeliveryRegister} />
        <Route path="/Worker-Registration" exact component={WorkerRegistration} />
        <Route path="/Thankyou" exact component={ThankYou} />
        <PrivateRoute path="/react-admin" component={Dashboard} />
        <PrivateRoute path="/react-admin/worker-admin" exact component={WorkerAdmin} />
        <PrivateRoute path="/react-admin/company-admin" exact component={CompanyAdmin} />
        <PrivateRoute path="/react-admin/delivery-admin" exact component={DeliveryAdmin} />
        <PrivateRoute path="/react-admin/resident-admin" exact component={ResidentAdmin} />
        <PrivateRoute path="/react-admin/sendWorker" exact component={SentWorker} />
        <PrivateRoute path="/react-admin/user-details" exact component={WorkerDetail} />
      </AuthProvider>
    </Router>
  );
}

export default App;
