import React, { useState } from "react";
import { Link } from 'react-router-dom'
import { faFacebook, faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelopeOpen, faPhone, faDoorOpen, faPersonBooth, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavDropdown } from 'react-bootstrap'
import './header.style.css'
const Header = () => {
    const [isToggled, setToggled] = useState(false);
    const toggleTrueFalse = () => setToggled(!isToggled);

    return (
        <div style={{marginBottom:'12rem'}}>
            <div id="topbar" class="d-none d-lg-flex align-items-center fixed-top">
                <div class="container d-flex">
                    <div class="contact-info mr-auto">
                        <a href="mailto:info@betterlabour.com"> <FontAwesomeIcon icon={faEnvelopeOpen} /> info@betterlabour.com</a>
                        <a href="tel:4165355188"> <FontAwesomeIcon icon={faPhone} /> Call us</a>
                    </div>
                    <div class="social-links">
                        <a className="" rel="noreferrer" target="_blank" href="https://betterlabour.com/login/"><FontAwesomeIcon icon={faDoorOpen} className="mr-1" /> Login</a>
                        <Link className="" to="/Worker-Registration"><FontAwesomeIcon icon={faPersonBooth} className="mr-2" />Worker Register</Link>
                        <a className="" rel="noreferrer" target="_blank" href="https://www.facebook.com/BetterLabourInc/"><FontAwesomeIcon icon={faFacebook} /></a>
                        <a className="" rel="noreferrer" target="_blank" href="https://twitter.com/LabourBetter"><FontAwesomeIcon icon={faTwitter} /></a>
                        <a className="" rel="noreferrer" target="_blank" href="https://www.linkedin.com/company/better-labour-inc/"><FontAwesomeIcon icon={faLinkedin} /></a>
                    </div>
                </div>
            </div>
                <div  id="header"className="navbar navbar-default navbar-light navbar-expand-lg  fixed-top">
                <Link className="navbar-brand " to="/"><img className="navbar-brand" style={{ width: '20vw' }} src={'images/logo.png'} alt="BETTERLABOUR" /></Link>
                <button className="navbar-toggler" type="button" onClick={toggleTrueFalse}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={"collapse navbar-collapse " + (isToggled ? "show" : "")}>
                    <div className="navbar-nav nav flex-fill justify-content-center social-links">
                        <NavDropdown title="Rent Workers" id="collasible-nav-dropdown">
                            <NavDropdown.Item style={{color:'black'}} href="/CompanyRegister">Companies</NavDropdown.Item>
                            <NavDropdown.Item style={{color:'black'}} href="/ResidentRegister">Residental</NavDropdown.Item>
                        </NavDropdown>
                        <a className="nav-item nav-link" href="https://betterlabour.com/jobs/">Need A Job</a>
                        <Link className="nav-item nav-link" to="/DeliveryPlus">Delivery Plus</Link>
                        <a className="nav-item nav-link" href="https://betterlabour.com/shop-face-masks/">Purchase Mask</a>

                    </div>
                    <div className="navbar-nav nav d-flex flex-row social-links justify-content-center">
                    <Link className="nav-item nav-link" to="/Login"><FontAwesomeIcon icon={faUsers} className="mr-1" /> Admin</Link>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Header