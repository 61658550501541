import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import './reset.style.css';
import firebase from 'firebase/app';
import 'firebase/auth';

const Login = ({ history }) => {

    const [loginInfo, setLoginInfo] = useState({
        loginUser: '',
        loginPassword: ''
    });

    const handleLogin = useCallback(
        async event => {
            event.preventDefault();
            const { loginUser, loginPassword } = event.target.elements;
            try {
                firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
                    .then(() => {
                        return firebase.auth().signInWithEmailAndPassword(loginUser.value, loginPassword.value);
                    })
                    .then(() =>{
                        history.push('/react-admin');
                    })
                    .catch((error) => {
                        alert(error)
                    })
               
            }catch (error) {
                alert(error);
            }
        }, [history]
    );
        
    const [resetInfo, setResetInfo] = useState({
        email: '',
    });

    const forgotPassword = async (event) => {
        event.preventDefault();
        const { email } = event.target.elements;
        console.log(email.value);
        try {
            await firebase.auth().sendPasswordResetEmail(email.value);
            alert('Email Sent!');
        } catch (error) {
            alert(error);
        }
    };

    return (

        <div>
            <form onSubmit={forgotPassword}>
                <div id="reset-password" class="modal-window">
                    <div>
                        <a href="#" title="Close" class="modal-close">
              Close
                        </a>
                        <h1>Password Reset</h1>
                        <br />
                        <div>Enter your email to reset your password!</div>
                        <br />
                        <input
                            name="email"
                            placeholder="E.g. xyz@gmail.com"
                            style={{ width: '100%' }}
                            value={resetInfo.email}
                            onChange={(e) =>
                                setResetInfo({
                                    ...resetInfo,
                                    [e.target.name]: e.target.value,
                                })
                            }
                        />
                        <br />
                        <button className="login-button" type="submit">
              Done
                        </button>
                    </div>
                </div>
            </form>

            <div style={{ margin: '25px' , width: '50vw' }}>
                <div className="box-top">
                    <h5> Admin Login</h5>
                </div>
                <div className="box-mid" >
                    <form onSubmit={handleLogin}>
                        <div className="form-group">
                            <label className="form-text text-muted" htmlFor="loginUser">EMAIL ADDRESS</label>
                            <input
                                className="login-input-wide form-control"
                                id="loginUser"
                                type="text"
                                value={loginInfo.loginUser}
                                name="loginUser"
                                onChange={(e) => setLoginInfo({
                                    ...loginInfo,
                                    [e.target.name]: e.target.value
                                })}
                            />
                        </div>
                        <div className="form-group">
                            <label className="form-text text-muted" htmlFor="loginPassword">PASSWORD</label>
                            <input
                                className="login-input-wide form-control"
                                id="loginPassword"
                                type="password"
                                value={loginInfo.loginPassword}
                                name="loginPassword"
                                onChange={(e) => setLoginInfo({
                                    ...loginInfo,
                                    [e.target.name]: e.target.value
                                })}
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">LOGIN</button>
                        
                    </form>
                    <button className="btn"><a href="#reset-password">Forgot Password?</a></button>
                </div>
            </div>
        </div>
    );
};

export default withRouter(Login);