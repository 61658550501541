import React from 'react'
import ResidentForm from '../../components/_form/resident.form'
import Header from '../../components/_header/header.component'
import Footer from '../../components/_footer/footer.component'
const ResidentRegister = () => {

    return (
        <>
            <Header />
            <div className="container pt-4 justify-content-center">
                <h5>Rent Resident Workers</h5>
                <div class="body">
                    <ResidentForm />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ResidentRegister