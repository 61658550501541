import React from 'react';
import Header from '../../components/_header/header.component'
import Footer from '../../components/_footer/footer.component'
import HeroHome from './_hero/hero.home'
import FeaturedServices from './_featured/featured.home'
import About from './_about/about.home'
import CountHome from './_count/count.home'
import TestimonialHome from './_testimonials/testimonials.home'
import ServiceHome from './_services/service.home'
import ContactHome from './_contact/contact.home'
const Home = () => {
    return(
        <>
            <Header />
            <HeroHome/>
            <FeaturedServices/>
            <About/>
            <CountHome/>
            <TestimonialHome/>
            <ServiceHome/>
            <ContactHome/>
            <Footer />
        </>
    )
}

export default Home;