import React from 'react';
import './testimonials.style.css'
import Carousel from 'react-bootstrap/Carousel'

const TestimonialHome = () => {
    return (
        <section id="testimonials" class="testimonials">
            <div class="" data-aos="fade-up">

                <div class="section-title">
                    <h2>Testimonials</h2>
                </div>

                <Carousel style={{ background: 'black' }}>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={"images/testimonials-bg.jpg"}
                            alt="First slide"
                        />
                        <Carousel.Caption style={{ position: 'absolute', top: '40%' }}>
                            <img src={"images/testimonials/testimonials-1.jpg"} class="testimonial-img" alt="" />
                            <h3 className="testimonial-h3">Saul Goodman</h3>
                            <h4 className="testimonial-h4">Ceo &amp; Founder</h4>
                        </Carousel.Caption>
                        <Carousel.Caption>
                            <p>Better Labour has been sending us workers now for over 6 years. They have effectively supplied us with as many as 60 workers at a time. Theyare committed to helping us achieve success in our business by providing a qualified diverse work force to meet our changing needs. Overall we are pleased with their service.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={"images/testimonials-bg.jpg"}
                            alt="First slide"
                        />
                        <Carousel.Caption style={{ position: 'absolute', top: '40%' }}>
                            <img src={"images/testimonials/testimonials-2.jpg"} class="testimonial-img" alt="" />
                            <h3>Saul Goodman</h3>
                            <h4>Ceo &amp; Founder</h4>
                        </Carousel.Caption>
                        <Carousel.Caption>
                            <p>Better Labour has been sending us workers now for over 6 years. They have effectively supplied us with as many as 60 workers at a time. Theyare committed to helping us achieve success in our business by providing a qualified diverse work force to meet our changing needs. Overall we are pleased with their service.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={"images/testimonials-bg.jpg"}
                            alt="First slide"
                        />
                        <Carousel.Caption style={{ position: 'absolute', top: '40%' }}>
                            <img src={"images/testimonials/testimonials-3.jpg"} class="testimonial-img" alt="" />
                            <h3>Saul Goodman</h3>
                            <h4>Ceo &amp; Founder</h4>
                        </Carousel.Caption>
                        <Carousel.Caption>
                            <p>Better Labour has been sending us workers now for over 6 years. They have effectively supplied us with as many as 60 workers at a time. Theyare committed to helping us achieve success in our business by providing a qualified diverse work force to meet our changing needs. Overall we are pleased with their service.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>

            </div>
        </section>

    )
}

export default TestimonialHome;