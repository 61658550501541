import * as Yup from 'yup';

const alphaNumISHRegExp = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/  
const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
// const numeric = something

const validation= Yup.object().shape({
  name: Yup.string()
  .min(2, "* Name is required.")
  .required(),
  email: Yup.string()
  .email("* Must enter a valid email address")
  .required(),
  address: Yup.string()
  .required("* Street is required"),
  phonenumber: Yup.string()
  .matches(phoneRegExp, "* Phone number is not valid")
  .required("* Phone number required"),
  companies: Yup.string()
  .required("* Street is required"),
});

export default validation